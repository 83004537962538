import { ipUrl } from '@/utils/config'

const map = {
  [`//${ipUrl}:7200/`]: "//demo.mms.sointech.com.cn/demo-vue2/",
  [`//${ipUrl}:7300/`]: "//demo.mms.sointech.com.cn/child_project02/",
  [`//${ipUrl}:7400/`]: "//demo.mms.sointech.com.cn/child_demo/",
  [`//${ipUrl}:8088/`]: "//demo.mms.sointech.com.cn/main-vue/",
  // "//192.168.0.201:80/": "//192.168.0.201:80/",
};

export default function hostMap(host) {
  if (process.env.NODE_ENV === "production") return map[host];
  return host;
}
