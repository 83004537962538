import "whatwg-fetch"; // fetch polyfill
import "custom-event-polyfill";

import Vue from "vue";
// 加入
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
//结束

import App from "./App.vue";
import store from './store'
import router from "./router";
import WujieVue from "wujie-vue2";
import hostMap from "./hostMap";
import credentialsFetch from "./fetch";
import BaiduMap from 'vue-baidu-map'

// 加入
// import directive from './directive' // directive
import plugins from './plugins' // plugins
import { download } from '@/utils/request'

import './assets/icons' // icon
import './permission' // permission control
// 结束

// 统一ip
import { ipUrl } from '@/utils/config'

import Switch from "ant-design-vue/es/switch";
import Tooltip from "ant-design-vue/es/tooltip";
import button from "ant-design-vue/es/button/index";
import Icon from "ant-design-vue/es/icon/index";
import "ant-design-vue/es/button/style/index.css";
import "ant-design-vue/es/style/index.css";
import "ant-design-vue/es/switch/style/index.css";
import "ant-design-vue/es/tooltip/style/index.css";
import "ant-design-vue/es/icon/style/index.css";
import lifecycles from "./lifecycle";
// import plugins from "./plugin";
import { Table } from "ant-design-vue";
import 'ant-design-vue/lib/table/style/css'
Vue.component(Table.name, Table)
// 加入
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 字典标签组件
import DictTag from '@/components/DictTag'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
// 结束
Vue.use(ElementUI);

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Editor', Editor)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)

// window.setTimeout(()=>{
//     Vue.use(directive)
// });

Vue.use(plugins)
Vue.use(VueMeta)
DictData.install()

// 引入Echarts
import *as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// Vue.use(BaiduMap, {
//     ak: '5ozyV9BTLjmea3Cg67x11qgH8FBNOjLH'
// });

/** 将需要共享的包挂载到主应用全局 */
// window.BaiduMap = BaiduMap;

const isProduction = process.env.NODE_ENV === "production";
const { setupApp, preloadApp, bus } = WujieVue;
Vue.use(WujieVue).use(Switch).use(Tooltip).use(button).use(Icon);

Vue.config.productionTip = false;

bus.$on("click", (msg) => window.alert(msg));

// 在 xxx-sub 路由下子应用将激活路由同步给主应用，主应用跳转对应路由高亮菜单栏
bus.$on("sub-route-change", (name, path) => {
    const mainName = `${name}`;
    const mainPath = `/${name}${path}`;
    const currentName = router.currentRoute.name;
    const currentPath = router.currentRoute.path;
    // console.log("mainName: "+mainName);
    // console.log("mainPath: "+mainPath);
    // console.log("currentName: "+currentName);
    // console.log("currentPath: "+currentPath);
    if (mainName === currentName && mainPath !== currentPath) {
        router.push({ path: mainName });
    }
});

// token过期跳转登陆页面
bus.$on("sub-login-change", (name, path) => {
    console.log("token过期跳转登陆页面:==========",name , path);
    router.push({ path: "/login" });
});

const degrade = window.localStorage.getItem("degrade") === "true" || !window.Proxy || !window.CustomElementRegistry;
const props = {
    jump: (name) => {
        router.push({ name });
    },
};
/**
 * 大部分业务无需设置 attrs
 * 此处修正 iframe 的 src，是防止github pages csp报错
 * 因为默认是只有 host+port，没有携带路径
 */
const attrs = isProduction ? { src: hostMap(`//${ipUrl}:8088/`) } : {};
/**
 * 配置应用，主要是设置默认配置
 * preloadApp、startApp的配置会基于这个配置做覆盖
 */

setupApp({
    name: "demo-vue2",
    url: hostMap(`//${ipUrl}:7200/`),
    attrs,
    exec: true,
    props,
    // alive: true,
    fetch: credentialsFetch,
    degrade,
    ...lifecycles,
    plugins: [
        {
            appendOrInsertElementHook(element, iframeWindow) {
                if (
                    element.nodeName === "svg" &&
                    (element.getAttribute("aria-hidden") === "true" ||
                        element.style.display === "none" ||
                        element.style.visibility === "hidden" ||
                        (element.style.height === "0px" && element.style.width === "0px"))
                ) {
                    iframeWindow.__WUJIE.styleSheetElements.push(element);
                }
            },
        },
    ],
});

setupApp({
    name: "project02",
    url: hostMap(`//${ipUrl}:7300/`),
    attrs,
    exec: true,
    alive: false,
    props,
    fetch: credentialsFetch,
    degrade,
    ...lifecycles,
    plugins: [
        {
            jsIgnores: [/amap\.com/], // [/baidu/]
            // _element为真正插入的元素， _iframeWindow为子应用的window，rawElement为原始插入元素
            appendOrInsertElementHook(_element, _iframeWindow) {
                // 以下函数也可以用此行代替 _iframeWindow.document.body.removeChild=() => null
                _iframeWindow.document.body.removeChild = () => null
            },
        },
    ],
    // plugins: [
    //     {
    //       // 排除子应用的这个脚本不加载，防止报错
    //       jsExcludes: ['https://api.map.baidu.com/api?v=1.0&type=webgl&ak=5ozyV9BTLjmea3Cg67x11qgH8FBNOjLH'],
    //       // 在子应用所有的js之前
    //       jsBeforeLoaders: [
    //         // 插入一个外联脚本
    //         { src: 'https://api.map.baidu.com/api?v=1.0&type=webgl&ak=5ozyV9BTLjmea3Cg67x11qgH8FBNOjLH' }
    //       ]
    //     },
    //     { jsIgnores: ['https://api.map.baidu.com/api?v=1.0&type=webgl&ak=5ozyV9BTLjmea3Cg67x11qgH8FBNOjLH'] }
    //   ]
});
// demo子项目
setupApp({
    name: "child_demo",
    url: hostMap(`//${ipUrl}:7400/`),
    attrs,
    exec: true,
    alive: false,
    props,
    fetch: credentialsFetch,
    degrade,
    ...lifecycles,
    plugins: [
        {
            jsIgnores: [/amap\.com/], // [/baidu/]
            // _element为真正插入的元素， _iframeWindow为子应用的window，rawElement为原始插入元素
            appendOrInsertElementHook(_element, _iframeWindow) {
                // 以下函数也可以用此行代替 _iframeWindow.document.body.removeChild=() => null
                _iframeWindow.document.body.removeChild = () => null
            },
        },
    ],
});


// setupApp({
//   name: "vue3",
//   url: hostMap(`//${ipUrl}:7300/`),
//   attrs,
//   exec: true,
//   alive: true,
//   plugins: [{ cssExcludes: ["https://stackpath.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"] }],
//   props,
//   // 引入了的第三方样式不需要添加credentials
//   fetch: (url, options) =>
//     url.includes(hostMap(`//${ipUrl}:7300/`)) ? credentialsFetch(url, options) : window.fetch(url, options),
//   degrade,
//   ...lifecycles,
// });

if (window.localStorage.getItem("preload") !== "false") {
    preloadApp({
        name: "demo-vue2",
    });

    preloadApp({
        name: "child_project02",
    });

    preloadApp({
        name: "child_demo",
    });

    // preloadApp({
    //     name: "screenDisplayUi",
    // });
    // if (window.Proxy) {
    //   preloadApp({
    //     name: "vue3",
    //   });
    // }
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
