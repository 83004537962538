<template>
    <div class="home">
        <div><img :src="logo" style="width: 300px;height: 150px;margin: 100px auto;" alt="" srcset="">
        </div>
        <!-- <div><img src="../assets/images/01.jpg" alt="" ></div> -->
        <div class="text">欢迎使用多式联运管理系统</div>

    </div>
</template>

<script>
import logoImg from '@/assets/logo/logo2.png'

export default {
    name: "Home",
    data() {
        return {
            logo: logoImg
        };
    },
    mounted() {
        if(this.$store.state.user.logo){
            this.logo = this.$store.state.user.logo;
        }
    },
};
</script>

<style scoped lang="scss">
.home {
    text-align: center;

    .text {
        font-size: 32px;
        font-weight: bolder;
        color: #000000;
    }
}


@media screen and (max-width: 768px) {}

#daiduMap,
#leafletmap {
    width: 400px;
    height: 400px;
}
</style>