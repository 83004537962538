<template>
  <div v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path, onlyOneChild.query)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
  },
  mounted(){
    // console.log(this.item,888);
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
      }
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // 首页只需显示一级
      if((parent.path === '' || parent.path === '/index') && showingChildren.length === 1){
        return true
      }
      /**
       * When there is only one child router, the child router is displayed by default
       * 当只有一个子路由器时，默认情况下会显示子路由器
       */
      if (showingChildren.length === 1 && parent.type === 'C') {
        return true
      }

      /**
       * When there is only one child router, the child router is displayed by default
       * 当只有一个子路由器时，默认情况下会显示子路由器
       */
    //    if (showingChildren[0].meta.title == '监控大屏' || parent.meta.title == '监控大屏') {
    //     return true
    //   }


      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath, routeQuery) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      if (routeQuery) {
        let query = JSON.parse(routeQuery);
        return { path: path.resolve(this.basePath, routePath), query: query }
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu .el-submenu ::v-deep .el-submenu__title {
    padding-left: 10px !important;
}

.el-menu .el-submenu ::v-deep ul .el-submenu__title {
    padding-left: 20px !important;
}
::v-deep .el-menu-item{
    padding-left: 10px !important;
}
#app .sidebar-container .svg-icon{
    margin-right: 8px;
}
#app .sidebar-container .el-menu-item, #app .sidebar-container .el-submenu__title{
    span{
        position: relative;
        top: -2px;
    }
}
#app .sidebar-container .theme-dark .nest-menu .el-submenu > .el-submenu__title, #app .sidebar-container .theme-dark .el-submenu .el-menu-item {
    font-size: 12px!important;
    padding-left: 22px!important;
    span{
        position: relative;
        top: -1px;
    }
}
</style>
